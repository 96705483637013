/* global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital@0;1&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap') */
@layer components{
  .wave{
    height: 200px; /* Adjusted height */
    position: absolute; 
   
    width: 100%; /* Take full width of the viewport */
   
 
    background: linear-gradient(90deg, #27b6b9 0%, #0f4061 100%);
    rotate: -70deg;
    /* transform: skewY(-30deg); Skew the wave */
  }
  
  
  
  .wave::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 20px 20px;
    background-image:
     radial-gradient(circle at 10px -5px, transparent 12px, #fff 13px);
  }
  .wave::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 15px;
    background-size: 40px 20px;
    background-image:
      radial-gradient(circle at 10px 15px, #fff 12px, transparent 13px);
  }
}





  /* body {
    overflow: hidden;
    background-color: #fff; 
  }

  .wavy-left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M0,40 C20,50 40,60 60,50 C80,40 100,20 100,40 L100,100 L0,100 Z" fill="currentColor"/></svg>') repeat-x;
    animation: wavy 2s infinite linear;
    z-index: 10;
  }

  .wavy-right::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M100,40 C80,50 60,60 40,50 C20,40 0,20 0,40 L0,100 L100,100 Z" fill="currentColor"/></svg>') repeat-x;
    animation: wavy 2s infinite linear;
    z-index: 10;
  }

  @keyframes wavy {
    0% { transform: translateY(0) translateX(-25%); }
    50% { transform: translateY(-10px) translateX(-25%); }
    100% { transform: translateY(0) translateX(-25%); }
  } */

